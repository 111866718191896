import { Box, Typography } from '@mui/material'
import LinkButton from '@talentinc/gatsby-theme-ecom/components/Link/LinkButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import useBrand from '../../hooks/useBrand'
import startCase from 'lodash/startCase'

interface Props {}

function CareerResources(props: Props) {
  const {} = props
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { flagshipProduct, display_name: brandName } = useBrand()

  const items = t('home.careerResources.items', {
    returnObjects: true,
    defaultValue: [],
    flagshipProduct: startCase(flagshipProduct),
  }) as { title: string; href: string; img: string }[]

  return (
    <Box className={classes.container}>
      <Box className={classes.row}>
        <Box className={classes.headerItem}>
          <Typography variant={'h1'}>{t('home.careerResources.header')}</Typography>
          <br />
          <Typography variant={'body1'}>
            {t('home.careerResources.description', { flagshipProduct, brandName })}
          </Typography>
        </Box>
        <Box className={classes.btnContainer}>
          <Box>
            <LinkButton href="/career-advice" variant={'outlined'}>
              {t('home.careerResources.viewCareerResources')}
            </LinkButton>
          </Box>
        </Box>
      </Box>
      <Box className={classes.contentRow}>
        {items.map((item, index) => {
          return (
            <a key={index} href={item.href} className={classes.item}>
              <img src={item.img} />
              <Box className={classes.row}>
                <Typography className={classes.title} variant={'h3'}>
                  {item.title}
                </Typography>
              </Box>
            </a>
          )
        })}
      </Box>
    </Box>
  )
}

export default CareerResources

const useStyles = makeStyles()((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'space-between',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  title: {
    color: theme.colors.neutral[90],
  },
  headerItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      alignSelf: 'flex-end',
    },
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginTop: 20,
    },
  },
  item: {
    textDecoration: 'none',
    flex: 1,
    '& img': {
      width: '100%',
      marginBottom: 10,
    },
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30,
    gap: 30,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
    },
  },
}))
