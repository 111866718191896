import { brand } from './common/brand'
import { footer } from './common/footer'
import { headerV2 } from './common/headerV2'

export default {
  title: 'New Homepage',
  slug: 'index',
  seo: {
    pageTitle: 'Best CV Writing Service | Professional CV Writers',
    description:
      'TopCV is one of the top cv writing services in the world. Our network of professional cv writers can help you land more interviews and job offers.',
    openGraphTitle: null,
    openGraphDescription: null,
    openGraphImage: null,
    noIndex: false,
    doNotDeployToProduction: false,
    pageType: null,
    canonicalUrl: null,
  },
  ...headerV2,
  ...brand,
  ...footer,
}
