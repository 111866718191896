import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useBrand from '../../hooks/useBrand'

type Item = {
  title: string
  description: string
  img: string
}

function Item({ item }: { item: Item }) {
  const { classes } = useStyles()

  return (
    <Box className={classes.item}>
      <Box className={classes.header}>
        <Typography className={classes.itemTitle} variant="h3">
          {item.title}
        </Typography>
        <Typography variant="body2">{item.description}</Typography>
      </Box>
      <img src={item.img} />
    </Box>
  )
}

function NewFeatures() {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { flagshipProduct, display_name: brandName } = useBrand()

  const items = t('home.newFeatures.items', {
    returnObjects: true,
    defaultValue: [],
    flagshipProduct,
    brandName,
  }) as Item[]

  return (
    <Box className={classes.container}>
      <Typography variant="h2">{t('home.newFeatures.header')}</Typography>
      <br />
      <Typography variant="body1">
        {t('home.newFeatures.subtitle', { flagshipProduct, brandName })}
      </Typography>
      <Box className={classes.row}>
        {items.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </Box>
    </Box>
  )
}

export default NewFeatures

const useStyles = makeStyles()((theme) => ({
  itemTitle: {
    color: theme.colors.neutral[90],
    marginBottom: 20,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    gap: 20,
    marginTop: 32,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
    },
  },
  item: {
    background: theme.colors.neutral[5],
    alignItems: 'center',
    borderRadius: 20,
    textAlign: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
      verticalAlign: 'top',
    },
    color: theme.colors.neutral[60],
  },
  header: {
    padding: 32,
    textAlign: 'center',
  },
}))
