import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

interface Props {}

const items = [
    { id: 1, src: 'https://images.ctfassets.net/7thvzrs93dvf/4oxcKLoSzcR4hN7rppq81i/c0871772fbb21fd70897dc4952530cf8/cbs.png' },
    { id: 2, src: 'https://images.ctfassets.net/7thvzrs93dvf/4oxcKLoSzcR4hN7rppq81i/f5f0d9893cb5fd7b71034df184ee4aef/fast.png' },
    { id: 3, src: 'https://images.ctfassets.net/7thvzrs93dvf/4oxcKLoSzcR4hN7rppq81i/05b529d1db234399061b2cc07a2625db/forbes.png' },
    { id: 4, src: 'https://images.ctfassets.net/7thvzrs93dvf/4oxcKLoSzcR4hN7rppq81i/c10328b57020d374484ba9728784562c/cnbc.png' },
    { id: 5, src: 'https://images.ctfassets.net/7thvzrs93dvf/25Jlci38VWn3UnbZNKFrbk/1ee7c4154bc748d076660b73f2f70d56/wallstreet.png' },
]

function FeaturedIn(props: Props) {
    const {} = props
    const { t } = useTranslation() 
    const { classes } = useStyles()

    return (
        <Box className={classes.container}>
            <Typography className={classes.title} variant="body2">{t('home.featuredIn.title')}</Typography>
            <Box className={classes.items}>
                {items.map(item => (
                    <img key={item.id} src={item.src} alt={`img${item.id}`} />
                ))}
            </Box>
        </Box>
    )
}

export default FeaturedIn

const useStyles = makeStyles()((theme) => ({
    title: {
        textTransform: 'uppercase',
        color: theme.colors.neutral[40],
        letterSpacing: 5,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        marginRight: 20
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    items: {
        display: 'flex',
        flexDirection: 'row',
        gap: 80,
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            gap: 20
        },
    }
}))